import {onFind} from "@elements/init-modules-in-scope";
import * as formValidation from '@elements/form-validation';

const selectors = {
    base: '.js-form-validation-tracking',
};

export function init() {
    onFind(selectors.base, function (baseElement) {
        let formInstance;
        let trackingEvent = baseElement.getAttribute('data-tracking-event');

        formValidation.getApi(baseElement).then((formApi) => {
            formInstance = formApi.getFormInstance();

            formInstance.on('core.form.valid', () => {
                dataLayer.push({
                    'event': trackingEvent
                })
            });
        });
    })
}

