import {onFind} from "@elements/init-modules-in-scope";
import {removeClass,addClass,on,findIn,findAllIn,closest} from '@elements/dom-utils';
import * as formValidation from '@elements/form-validation';

const selectors = {
    base: '.js-toggle-form',
    select: '.js-toggle-form__select',
    checkbox: '.js-toggle-form__checkbox',
    radio: '.js-toggle-form__radio',
    form: '.js-toggle-form__form',
    radioForm: '.js-toggle-form__radio-form',
    requiredField: '.js-toggle-form__required',
    ajaxForm: '.js-toggle-form__ajax-form'
};

export function init() {
    onFind(selectors.base, function (baseElement) {
        let checkbox = findIn(selectors.checkbox, baseElement);
        let select = findIn(selectors.select, baseElement);
        let form = findIn(selectors.form, baseElement);
        let radio = findAllIn(selectors.radio, baseElement);
        let requiredField = findIn(selectors.requiredField, baseElement);
        let ajaxForm = closest(selectors.ajaxForm, baseElement);
        let formInstance;

        if (select){
            //for commissioning form
            if (select.hasAttribute("data-block")){
                on('change', function() {
                    let ids = select.getAttribute("data-block").split(",");
                    let blockId = select.value
                    let block = document.getElementById("#" + blockId)

                    ids.forEach((id) => {
                        if (document.getElementById("#" + id)){
                            addClass('d-none', document.getElementById("#" + id));
                        }
                        if (id === blockId && block){
                            removeClass('d-none', block);
                        }
                    });
                }, select);
            }else{
                on('change', function() {
                    if(select.value === 'yes') {
                        removeClass('d-none', form);
                    } else {
                        addClass('d-none', form);
                    }
                }, select);
            }
        }

        if (checkbox){
            if (ajaxForm){
                formValidation.getApi(ajaxForm).then((formApi) => {
                    on('change', function () {
                        if (checkbox.getElementsByTagName('input')[0].checked) {
                            removeClass('d-none', form);

                            if (requiredField !== undefined) {
                                let target = requiredField.getElementsByTagName('input')[0]
                                target.setAttribute("required", "true")

                                formInstance = formApi.getFormInstance();
                                formInstance.destroy();
                                formValidation.init(target);
                            }
                        } else {
                            addClass('d-none', form);

                            if (requiredField !== undefined) {
                                let target = requiredField.getElementsByTagName('input')[0]
                                target.removeAttribute("required")

                                formInstance = formApi.getFormInstance();
                                formInstance.destroy();
                                formValidation.init(target);
                            }
                        }
                    }, checkbox);
                })
            }else{
                on('change', function () {
                    if (checkbox.getElementsByTagName('input')[0].checked) {
                        removeClass('d-none', form);
                    } else {
                        addClass('d-none', form);
                    }
                }, checkbox);
            }
        }

        if (radio){
            let trackingId = baseElement.getAttribute('data-tracking-id');

            radio.forEach((btn) => {
                on('change', function() {
                    let group = closest(selectors.radioForm, btn);
                    let form = findIn(selectors.form, group);

                    if (form){
                        if(btn.getElementsByTagName('input')[0].checked) {
                            if (typeof _trackingData !== 'undefined') {
                                if (_trackingData[trackingId]) {
                                    _trackingData[trackingId].datalayer.ecommerce.shipping_tier = btn.getElementsByTagName('label')[0].innerText;
                                }
                            }

                            removeClass('d-none', form);
                        }else{
                            addClass('d-none', form);
                        }
                    }else{
                        if (typeof _trackingData !== 'undefined') {
                            if (_trackingData[trackingId]) {
                                if(btn.getElementsByTagName('input')[0].checked) {
                                    _trackingData[trackingId].datalayer.ecommerce.shipping_tier = btn.getElementsByTagName('label')[0].innerText;
                                }
                            }
                        }
                    }
                }, baseElement);
            })
        }
    });
}

